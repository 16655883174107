import React, { useState, useContext } from 'react';

const AuthContext = React.createContext();

export default function Auth({ children }) {
  const authState = useState(null);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  const [auth, setAuth] = useContext(AuthContext);

  const onAccessToken = (token) => {
    setAuth({ token });

    fetchACCSearchResults(token);
  }

  const logout = () => {
    setAuth(null);
  };

  const getHeaders = () => {
    return {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    };
  };

  // Function to make the ACC Search API call
  const fetchACCSearchResults = async (token) => {
    const endpoint = 'https://us-central1-content-audit-333003.cloudfunctions.net/acc-search';
    // const endpoint = 'https://us-east4-cc-ai-dev-409415.cloudfunctions.net/search';
    // const endpoint = 'https://us-east1-high-impact-team.cloudfunctions.net/acc-search';
    // const endpoint = 'https://us-east4-cc-ai-dev-409415.cloudfunctions.net/content_audit_access';
    // token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjkzNGE1ODE2NDY4Yjk1NzAzOTUzZDE0ZTlmMTVkZjVkMDlhNDAxZTQiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiJodHRwczovL3VzLWVhc3Q0LWNjLWFpLWRldi00MDk0MTUuY2xvdWRmdW5jdGlvbnMubmV0L3NlYXJjaCIsImF6cCI6ImNjLWZ1bmN0aW9uLWFjY2Vzc0BjYy1haS1kZXYtNDA5NDE1LmlhbS5nc2VydmljZWFjY291bnQuY29tIiwiZW1haWwiOiJjYy1mdW5jdGlvbi1hY2Nlc3NAY2MtYWktZGV2LTQwOTQxNS5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJleHAiOjE3MTIxNzk2NDYsImlhdCI6MTcxMjE3NjA0NiwiaXNzIjoiaHR0cHM6Ly9hY2NvdW50cy5nb29nbGUuY29tIiwic3ViIjoiMTExNzQ3NDA3MDY2MzYyMzI2MzY2In0.fDlTfoamWwYDUbzZRz2ohcUQ4v0ZDjydoyLym7Uw5ZaDh_3cW5ApwXNnVxMH_Mpv7r6dKHASE-P4W3nAAo1bGo681gHcMYFKXeMqgLBhw0LNcoIXdO2N5YPAQTq4h3lB50EqhHSAY34uRCrDfJXmyJKEMzr-Jeji0qz1J9RXrkyrKpJqnlU8Efn24qvQRU-e0oovDS75Io2lMgJ1OFhckSPHG3LvWejF4osPf3ousyrrEh62fWQHaMJMPefVORDg44v-c54REIFnq2Fj05T_w81KF3ChmwI9N8-LLtn7rKBhWA35QAbAXZvaCMi0MRAUv9-V0eOicnuLlUIwyTN5bw';
    const requestData = {
      query: "tell me about the wildfire"
    };

    //const url = `http://localhost:5000/proxy/?url=${encodeURIComponent(endpoint)}`

    try {
      const response = await fetch(endpoint, {
        method: 'POST', 
        credentials: 'include',  // if dealing with cookies
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json(); 
      console.log(data); 
      return data;
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  return { onAccessToken, logout, getHeaders, isAuthenticated: !!auth?.token };
}
